<!-- @format -->
<script lang="ts">
  import type { ManageAPI } from "~/manage-api/types";

  // Define a type for our Liferay banner structure
  type LiferayBanner = {
    mobileImageURL: string;
    desktopImageURL: string;
    text: string;
    linkURL?: string;
  };

  // Accept either type
  export let banner: ManageAPI.Banner | LiferayBanner;
  
  $: href = banner.linkURL;
  $: desktopImage_src = banner.desktopImageURL;
  $: mobileImage_src = banner.mobileImageURL;
  $: text = banner.text;
</script>

{#if href}
  <a {href} class="position-relative">
    <picture>
      <source srcset={desktopImage_src} media="(min-width: 768px)" />
      <img src={mobileImage_src} alt={text} />
    </picture>
    {#if text}
      <div class="text">
        <div class="container-fluid container-fluid-max-xl">
          <div class="row">
            <div class="col-12 col-lg-6">
              <p>{text}</p>
            </div>
          </div>
        </div>
      </div>
    {/if}
  </a>
{:else}
  <div class="position-relative">
    <picture>
      <source srcset={desktopImage_src} media="(min-width: 768px)" />
      <img src={mobileImage_src} alt={text} />
    </picture>
    {#if text}
      <div class="text">
        <div class="container-fluid container-fluid-max-xl">
          <div class="row">
            <div class="col-12 col-lg-6">
              <p>{text}</p>
            </div>
          </div>
        </div>
      </div>
    {/if}
  </div>
{/if}

<style lang="scss">
  .text {
    position: absolute;
    top: 66.66%;
    transform: translateY(-66.66%);
    font-weight: 700;
    font-size: 42px;
    color: #ffffff;
    width: 100%;
    line-height: 110%;
    letter-spacing: -0.03em;
    
    @media screen and (min-width: 768px) {
      font-size: 80px;
    }
    @media screen and (min-width: 992px) {
      font-size: 90px;
    }
    @media screen and (min-width: 1440px) {
      font-size: 100px;
    }
  }

  picture {
    width: 100%;
  }

  img {
    width: 100%;
    aspect-ratio: 15/22;
    object-fit: cover;
    
    @media screen and (min-width: 992px) {
      aspect-ratio: 12/5;
    }
  }

  a {
    display: block;
    width: 100%;
  }
</style>
