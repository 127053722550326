<script lang="ts">
  import { onMount } from "svelte";
  import Swiper from "swiper";
  import { Autoplay, Navigation, Pagination } from "swiper/modules";
  import type { SwiperOptions } from "swiper/types";
  import { generateRandomID } from "~/util/common";
  import type { ManageAPI } from "~/manage-api/types";
  import BannerItem from "./BannerItem.svelte";

  export let banners: ManageAPI.Banner[];
  
  const uniqueId = generateRandomID();
  let swiperEl: HTMLElement | null = null;
  let swiperInstance: Swiper;

  const params: SwiperOptions = {
    pagination: {
      el: `.pagination-${uniqueId}`,
      clickable: true,
    },
    navigation: {
      nextEl: `.nav-next-${uniqueId}`,
      prevEl: `.nav-prev-${uniqueId}`,
    },
    autoplay: { delay: 5000 },
    loop: true,
    modules: [Autoplay, Navigation, Pagination],
  };

  onMount(() => {
    if (!swiperEl) return;
    swiperInstance = new Swiper(swiperEl, params);
  });
</script>

<div class="swiper main-banner-swiper" bind:this={swiperEl}>
  <div class="swiper-wrapper">
    {#each banners as banner}
      <div class="swiper-slide">
        <BannerItem {banner} />
      </div>
    {/each}
  </div>

  <div class="control">
    <div class="control-navigation">
      <div class={`swiper-button-prev nav-prev-${uniqueId}`}></div>
      <div class={`swiper-button-next nav-next-${uniqueId}`}></div>
    </div>
    <div class={`swiper-pagination pagination-${uniqueId}`}></div>
  </div>
</div>

<style lang="scss">
  .main-banner-swiper {
    position: relative;
    opacity: 0;
    transition: opacity 0.3s ease;

    &.swiper-initialized {
      opacity: 1;
    }

    .swiper-slide {
      opacity: 1;
      visibility: visible;
    }

    .control {
      position: absolute;
      width: 100%;
      bottom: 0;
      height: auto;
      padding-left: 12px;
      padding-right: 12px;
      padding-bottom: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      z-index: 1;
      
      @media only screen and (min-width: 992px) {
        justify-content: flex-start;
        padding-left: 64px;
        padding-right: 64px;
        padding-bottom: 64px;
      }
    }

    :global([class*='nav-prev-']),
    :global([class*='nav-next-']) {
      width: 32px;
      height: 32px;
      border: 1px solid #fff;
      border-radius: 50%;
      position: unset;
      display: inline-flex;
      margin-right: 16px;

      &::after {
        font-size: 15px;
        color: #fff;
      }
    }

    :global([class*='pagination-']) {
      width: fit-content;
      position: unset;
      display: flex;
      margin-left: 32px;
      gap: 8px;

      :global(.swiper-pagination-bullet) {
        width: 16px;
        height: 16px;
        margin: 0;
        background: transparent;
        border: 1px solid #fff;
        opacity: 1;
        border-radius: 0;
      }

      :global(.swiper-pagination-bullet-active) {
        background: #fff;
      }
    }
  }
</style> 